import { Address } from "./UtilityComponets";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { db } from "../../../utils/DataRefresher";
import {
  CheckFieldErrors,
  FieldError,
  OutputFieldValue,
} from "./UtilityComponets";
import { Message } from "primereact/message";

const USDollar = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

function Jobs({ jobData }) {
  const JobRow = ({ name, partner, manager, period, est, classes }) => (
    <div className={`flex flew-row  pl-2 ${classes}`}>
      <div className="w-4">{name}</div>
      <div className="w-2">{partner ? partner : "Same as Service Partner"}</div>
      <div className="w-2">{manager ? manager : "Same as Service Manager"}</div>
      <div className="w-2">{period}</div>
      <div className="w-2">{est}</div>
    </div>
  );

  const jobList = jobData.map((data, index) => {
    return (
      <JobRow
        key={data.jobInfo.JOB_IDX}
        id={data.jobInfo.JOB_IDX}
        classes={"review-job-row py-2"}
        name={data.jobInfo.NAME}
        partner={data.jobPartner.EMPLOYEE}
        manager={data.jobManager.EMPLOYEE}
        period={new Date(data.jobPeriodEnd).toDateString()}
        est={USDollar(Number(data.jobEstimate))}
      />
    );
  });
  jobList.splice(
    0,
    0,
    <JobRow
      key={"HeaderRow"}
      id="row"
      name="Job"
      partner="Partner"
      manager="Manager"
      period={"Period End"}
      est={"Estimate"}
      classes={"review-job-row-header"}
    />
  );
  return jobList;
}

function Services({ serviceData, errors }) {
  const estimate = serviceData.jobs.reduce((acc, cur) => {
    return cur.jobSelected ? acc + Number(cur.jobEstimate) : acc;
  }, 0);
  // console.log(errors);
  const subTitle = () => {
    return (
      <div className="w-full flex justify-content-around">
        <span className="review-text-label">
          Service Partner:
          <span className="font-semibold review-text-lg ml-1">
            {errors && errors.servicePartner
              ? CheckFieldErrors(
                  "servicePartner",
                  errors,
                  serviceData,
                  "EMPLOYEE"
                )
              : serviceData.servicePartner && serviceData.servicePartner.EMPLOYEE
              ? serviceData.servicePartner.EMPLOYEE
              : ""}
          </span>
        </span>
        <span className="review-text-label">
          Service Manager:
          <span className="font-semibold review-text-lg ml-1">
            {errors && errors.serviceManager
              ? CheckFieldErrors(
                  "serviceManager",
                  errors,
                  serviceData,
                  "EMPLOYEE"
                )
              : serviceData.serviceManager && serviceData.serviceManager.EMPLOYEE
              ? serviceData.serviceManager.EMPLOYEE
              : ""}
          </span>
        </span>
        <span className="review-text-label">
          Total Estimate:
          <span className="font-semibold review-text-lg ml-1">
            {USDollar(estimate)}
          </span>
        </span>
      </div>
    );
  };

  return (
    <Card
      title={serviceData.SERVNAME}
      subTitle={subTitle()}
      style={{ border: "none", borderRadius: "0" }}
    >
      <Jobs
        jobData={
          serviceData.jobs
            ? serviceData.jobs.filter((_data) => _data.jobSelected)
            : "No Jobs Selected"
        }
      />
    </Card>
  );
}

export default function ReviewPage({
  data,
  visible,
  onHide,
  style,
  submitRef,
  callingPage,
  formState,
}) {
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    formState && setValidForm(formState.isValid);
  }, [formState, setValidForm]);

  const { errors } = formState ? formState : {};
  // console.log(data);
  // console.log(errors);

  function BuildAddress(field, city, state, postal) {
    this.field = field;
    this.city = city;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state = state;
    this.postal = postal;
  }

  const clientAddress = new BuildAddress(
    "Client",
    data.clientCity,
    data.clientState,
    data.clientPostalCode
  );

  const contactAddress = new BuildAddress(
    "Contact",
    data.contactCity,
    data.contactState,
    data.clientPostalCode
  );

  const billingAddress = new BuildAddress(
    "Billing",
    data.billingCity,
    data.billingState,
    data.billingPostalCode
  );

  const buildAddress = (errors, add_obj) => {
    const field = add_obj.field.toLowerCase();
    if (
      errors[`${field}City`] ||
      errors[`${field}State`] ||
      errors[`${field}PostalCode`]
    ) {
      add_obj.state = "";
      add_obj.postal = "";

      add_obj.city = FieldError({
        message: `${
          add_obj.field && add_obj.field
        } City State And Postal Code Required`,
      });
    }
  };

  errors && buildAddress(errors, clientAddress);
  errors && buildAddress(errors, contactAddress);
  errors && buildAddress(errors, billingAddress);

  return (
    <Dialog
      className="mx-width-pane review-popup sm:w-full lg:w-10"
      header={
        <span className="review-popup-header">
          New Client: {<h6>{data.clientName}</h6>}
        </span>
      }
      visible={visible}
      style={style}
      onHide={onHide}
      footer={
        <Footer
          onHide={onHide}
          client={data}
          submitRef={submitRef}
          callingPage={callingPage}
          isValidForm={validForm}
        />
      }
    >
      <div className="review-content flex flex-wrap">
        <Fieldset
          pt={{ legend: { className: "review-page-header" } }}
          legend={
            <div className="review-page-header-text">General Information</div>
          }
          className="w-full review-page-header"
        >
          <Address
            name={
              errors
                ? CheckFieldErrors("clientName", errors, data)
                : data.clientName
            }
            street={
              errors
                ? CheckFieldErrors("clientAddress", errors, data)
                : data.clientAddress
            }
            city={clientAddress.city}
            state={clientAddress.state}
            postalCode={clientAddress.postal}
            country={data.clientCountry}
          />
          <div className="flex flex-wrap w-full justify-content-left column-gap-4 row-gap-2">
            <div>
              {data.clientRelationship === "new" ? (
                "Originator: "
              ) : data.clientRelationship === "existing" ? (
                "Existing Client Code: "
              ) : (
                <span className="review-text-label">
                  Client Relationship:
                  <span className="pi pi-exclamation-circle field-error-display ">
                    Selection Required
                  </span>
                </span>
              )}
              <span className="font-semibold review-text-lg ">
                {data.relationshipValue && errors
                  ? CheckFieldErrors("relationshipValue", errors, data, "label")
                  : data.relationshipValue
                  ? data.relationshipValue.label
                  : ""}
                {/* {data.relationshipValue ? data.relationshipValue.label : ""}{" "} */}
              </span>
            </div>
            <div>
              <span className="review-text-label">Client Office:</span>
              <span className="font-semibold review-text-lg ml-1">
                {errors && errors.officeSelection
                  ? CheckFieldErrors("officeSelection", errors, data, "NAME")
                  : data.officeSelection
                  ? data.officeSelection.NAME
                  : ""}
              </span>
            </div>
            <div>
              <span className="review-text-label">Client Type:</span>
              <span className="font-semibold review-text-lg ml-1">
                {data.clientScope === "IND"
                  ? "Individual"
                  : data.clientScope === "ORG"
                  ? "Organization"
                  : errors && errors.clientScope
                  ? FieldError(errors.clientScope)
                  : ""}
              </span>
            </div>
            <div className="w-full flex gap-4">
              <div>
                <span className="review-text-label">Client Partner:</span>
                <span className="font-semibold review-text-lg ml-1">
                  {errors && errors.partnerSelection
                    ? FieldError(errors.partnerSelection)
                    : data.partnerSelection
                    ? data.partnerSelection.EMPLOYEE
                    : ""}
                </span>
              </div>
              <div>
                <span className="review-text-label">Client Manager:</span>
                <span className="font-semibold review-text-lg ml-1">
                  {errors && errors.managerSelection
                    ? FieldError(errors.managerSelection)
                    : data.managerSelection
                    ? data.managerSelection.EMPLOYEE
                    : ""}
                </span>
              </div>
            </div>
            <div>
              <span className="review-text-label">Entity Type:</span>
              <span className="font-semibold review-text-lg ml-1">
                {errors && errors.entitySelection
                  ? FieldError(errors.entitySelection)
                  : data.entitySelection
                  ? data.entitySelection.NAME
                  : ""}
              </span>
            </div>
            <div>
              <span className="review-text-label">Industry:</span>
              <span className="font-semibold review-text-lg ml-1">
                {errors && errors.industrySelection
                  ? FieldError(errors.industrySelection)
                  : data.industrySelection
                  ? data.industrySelection.NAME
                  : ""}
              </span>
            </div>
          </div>
          <div className="flex justify-content-center">
            {(data.govermentContractor === null ||
              data.govermentContractor === undefined) && (
              <Message
                severity="error"
                text="Must Select If Client Is Government Contractor Or Affiliated"
                icon="pi pi-exclamation-circle"
                className="text-red-800"
              />
            )}
            {(data.clientCreatedAfter2023 === null ||
              data.clientCreatedAfter2023 === undefined) &&
              data.clientScope === "ORG" && (
                <Message
                  severity="error"
                  text="Must declare if client was formed after 12/31/2023"
                  icon="pi pi-exclamation-circle"
                  className="text-red-800 ml-2"
                />
              )}
          </div>
        </Fieldset>
        <Fieldset
          legend="Contact Information"
          pt={{
            root: { className: "w-6" },
            legend: { className: "review-page-header" },
          }}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: "1px solid  #ebebeb",
          }}
        >
          <Address
            name={
              errors && (errors.contactFirstName || errors.contactLastName)
                ? FieldError({
                    message: "Contact First And Last Name Required",
                  })
                : `${data.contactFirstName} ${data.contactLastName}`
            }
            street={
              errors
                ? CheckFieldErrors("contactAddress", errors, data)
                : data.clientAddress
            }
            city={contactAddress.city}
            state={contactAddress.state}
            postalCode={contactAddress.postal}
            country={data.contactCountry}
          />
          <div>
            {/* <div>Contact: <span className='font-semibold text-lg'>{data.contactFirstName} {data.contactLastName}</span></div> */}
            <span className="review-text-label">Email:</span>
            <span className="font-semibold review-text-lg ml-1">
              {OutputFieldValue(errors, data, "contactEmail")}
            </span>
            <br />
            <span className="review-text-label">Phone:</span>
            <span className="font-semibold review-text-lg ml-1">
              {OutputFieldValue(errors, data, "contactPhoneNumber")}
            </span>
            <br />
          </div>
        </Fieldset>
        <Fieldset
          legend="Billing Information"
          pt={{
            root: { className: "w-6" },
            legend: { className: "review-page-header" },
          }}
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: "1px solid  #ebebeb",
          }}
        >
          <Address
            name={
              errors
                ? CheckFieldErrors("clientName", errors, data)
                : data.clientName
            }
            street={
              errors
                ? CheckFieldErrors("billingAddress", errors, data)
                : data.clientAddress
            }
            city={billingAddress.city}
            state={billingAddress.state}
            postalCode={billingAddress.postal}
            country={data.billingCountry}
          />
          <div>
            {/* <div>Contact: <span className='font-semibold text-lg'>{data.contactFirstName} {data.contactLastName}</span></div> */}
            <span className="review-text-label">Email:</span>
            <span className="font-semibold review-text-lg ml-1">
              {OutputFieldValue(errors, data, "billingEmail")}
            </span>
            <br />
            {/* <div>Phone: {data.billingPhoneNumber}</div> */}
            <span className="review-text-label">Preferred Invoice Method:</span>
            <span className="font-semibold review-text-lg ml-1">
              {OutputFieldValue(errors, data, "invoiceMethod")}
            </span>
          </div>
        </Fieldset>
        <Fieldset
          legend="Services & Job Information"
          pt={{
            root: { className: "w-full" },
            legend: { className: "review-page-header" },
          }}
        >
          {data.services && data.services.length > 0 ? (
            data.services.map((_data, index) => (
              <Services
                serviceData={_data}
                key={`${index}${_data.SERVCODE}`}
                errors={
                  errors && errors.services && errors.services[index]
                    ? errors.services[index]
                    : false
                }
              />
            ))
          ) : (
            <div className="flex justify-content-center">
              <Message
                severity="error"
                text="No Services or Jobs Selected"
                icon="pi pi-exclamation-circle"
                className="text-red-800"
              />
            </div>
          )}
        </Fieldset>
      </div>
    </Dialog>
  );
}

function Footer({ onHide, client, submitRef, callingPage, isValidForm }) {
  const navigate = useNavigate();

  const saveData = async (clientObject) => {
    console.log(clientObject);
    try {
      const addedClient = db.NEW_CLIENTS.put(clientObject);
      addedClient.then((data) =>
        console.log("Successfully Saved Client", data)
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-1">
      {callingPage !== "ncto" ? (
        // <Link to="/apps/ncto/form" state={{ clientData: client }}>
        <Button
          aria-label="Edit"
          tooltip="Edit"
          type="button"
          disabled={client.status === "IN PROGRESS" ? false : true}
          onClick={() => {
            if (client.status === "IN PROGRESS") {
              navigate("/apps/ncto/form", { state: { clientData: client } });
            }
          }}
        >
          Edit
        </Button>
      ) : (
        <Button aria-label="Edit" tooltip="Edit" type="button" onClick={onHide}>
          Edit
        </Button>
      )}
      {callingPage === "ncto" && (
        <Button
          disabled={!isValidForm}
          type="submit"
          onClick={() => {
            submitRef.current.requestSubmit();
            saveData(client);
          }}
        >
          Submit
        </Button>
      )}
    </div>
  );
}
