import React, { useEffect, useState, lazy, Suspense } from "react";
import { PageLayout } from "./components/Nav";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import { Help } from "./components/Help";

// import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
// import "primereact/resources/themes/soho-light/theme.css"
import "./style/primereact-theme.css";
import "./style/App.css";
import "./style/theme.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "./utils/redux/loginSlice";

import ClipLoader from "react-spinners/ClipLoader";
import RingLoader from "react-spinners/RingLoader";

import {
    // DataRefresher,
    db,
    GetRemoteVersions,
    RefreshData,
    // UpdateLessChangelog,
} from "./utils/DataRefresher";

// import { loginRequest } from "./authConfig";
import { RespWIP, FirmWIP, TeamWIP } from "./pages/reports/wip/WIPreports";
import { MyWIP } from "./pages/reports/wip/MyWIP";
import NCTO from "./pages/apps/NCTO/NCTO";
// import FirmHR from "./pages/reports/hr/FirmHR";
const Reports = lazy(() => import("./pages/reports/Reports"));
const Home = lazy(() => import("./pages/Home"));
// import Reports from "./pages/reports/Reports";
const PowerBI = lazy(() => import('./pages/reports/powerbi/Member'))
const FirmAR = lazy(() => import("./pages/reports/ar/FirmAR"));
// const DeptAR = lazy(() => import("./pages/reports/ar/DeptAR"));
const RespAR = lazy(() => import("./pages/reports/ar/RespAR"));
const FirmHR = lazy(() => import("./pages/reports/hr/FirmHR"))
const Member = lazy(() => import("./pages/reports/powerbi/Member"));
const Morale = lazy(() => import("./pages/people/Morale/Morale"));
const Review = lazy(() => import("./pages/people/Review/Review"));
const Bingo = lazy(() => import("./pages/apps/Bingo"));
// const NCTO = lazy(() => import("./pages/apps/NCTO/NCTO"))
const NCTOLandingPage = lazy(() => import("./pages/apps/NCTO/LandingPage"))
const Approval = lazy(() => import('./pages/apps/NCTO/Approval'))
const ApprovalListPage = lazy(() =>
    import("./pages/apps/NCTO/ApprovalListPage")
);
const BOI = lazy(() => import("./pages/apps/NCTO/BOI"));
const Reject = lazy(() => import('./pages/apps/NCTO/Reject'))

// const apiRoot = "https://mag-demo.azurewebsites.net";
// const apiRoot = "http://localhost:7071";

const Loading = props => {

    let loadingScreen

    switch (props.loader) {

        case 'ring':
            loadingScreen = (<RingLoader />)
            break;
        case 'clip':
            loadingScreen = (<ClipLoader />)
            break;
        default:
            loadingScreen = (<RingLoader
                color="Black"
                loading={props.dataRefreshingState}
                // cssOverride={props.override}
                size={500}
                aria-label="Refreshing Data"
                data-testid="data-refresh-loader"
            />)

    }
    return (
        <section style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            {loadingScreen}
        </section>
    )
}

function ActiveUserRoute(props) {
    const authenticated = useSelector((state) => state.user.authenticated);
    if (authenticated === true) {
        // if (true === true) {
        return (
            <Router>
                <PageLayout setShowHelp={props.setShowHelp}>
                    <Suspense fallback={<br></br>/*<Loading loader='ring' />*/} >
                        <Routes>
                            <Route
                                exact
                                path="/"
                                element={
                                    <Home setGraph={props.setGraph} inProgress={props.inProgress} />
                                }
                            />
                            <Route exact path="/reports" element={<Reports />} />
                            <Route exact path="/reports/ar/firm" element={<FirmAR />} />
                            {/* <Route exact path="/reports/ar/dept" element={<DeptAR />} /> */}
                            <Route exact path="/reports/ar/responsible" element={<RespAR />} />

                            <Route
                                exact
                                path="/reports/wip/responsible"
                                element={<RespWIP />}
                            />
                            <Route exact path="/reports/wip/firm" element={<FirmWIP />} />
                            <Route exact path="/reports/wip/self" element={<MyWIP />} />
                            {/* <Route exact path="/reports/wip/dept" element={<DeptWIP />} /> */}
                            <Route exact path="/reports/wip/team" element={<TeamWIP />} />

                            <Route exact path="/reports/powerbi/member" element={<Member />} />
                            <Route exact path="/people/morale" element={<Morale />} />
                            <Route exact path="/people/reviews" element={<Review />} />

                            <Route exact path="/reports/hr/firm" element={<FirmHR />} />

                            <Route exact path="/apps/ncto" element={<NCTOLandingPage />} />
                            <Route exact path="/apps/ncto/form" element={<NCTO />} />
                            <Route exact path="/apps/bingo" element={<Bingo />} />
                            <Route exact path="/apps/ncto/approve/:office" element={<ApprovalListPage />} />
                            <Route
                                path="/apps/ncto/approve/:office/:key"
                                element={<Approval />}
                            />
                            <Route
                                path='/apps/ncto/reject/:office/:key'
                                element={<Reject />}
                            />
                            <Route exact path="/apps/ncto/boi" element={<BOI />} />

                            {/* <Route exact path='/reports/powerbi/hr' Component={} /> */}
                        </Routes>
                    </Suspense>
                </PageLayout>
            </Router>
        );
    } else if (authenticated === false) {
        return (
            <p>Uh oh! Looks like you are not a registered user with this company</p>
        );
    } else {
        return <ClipLoader loading={true} />;
    }
}

export default function App() {
    const USER = useSelector((state) => state.user);
    const bearer = useSelector((state) => state.user.bearer);
    const apiRoot = useSelector(state => state.globals.apiRoot)
    const [showHelp, setShowHelp] = useState(false)
    const dispatch = useDispatch();
    

    const [dataRefreshingState, setDataRefreshingState] = useState(false);

    useEffect(() => {
        fetch(`${apiRoot}/api/staff/me/${USER.STAFFINDEX}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-functions-key":
                    "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
                Authorization: bearer,
            },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(
                        "Uh oh! Looks like you are not a registered user with this company"
                    );
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                dispatch(
                    setUser({ authenticated: true, ...data, bearer: bearer })
                );
                // setUserData(data[0])
            })
            .catch((err) => {
                console.error(err);
                dispatch(
                    setUser({
                        authenticated: false,
                        message:
                            "Uh oh! Looks like you are not a registered user with this company!",
                    })
                );
            })
    }, [USER.STAFFINDEX, dispatch, bearer, apiRoot]);

    useEffect(() => {
        console.log(USER);
        // console.log(bearer);
        const STAFF_DEPT = USER.DEPARTMENT;
        console.log("Staff Department: ", STAFF_DEPT);
        GetRemoteVersions({
            changeTable: `${apiRoot}/api/tablechangelog`,
            tableHeaders: {
                authorization:
                    bearer,
            },
        }).then((resp) => {
            // console.log(resp)
            let tableHeaders = { authorization: bearer }
            Promise.allSettled([
                RefreshData(
                    {
                        table: "UNPAID_AR",
                        endpoint: `${apiRoot}/api/ar/unpaid/full`,
                        remoteversion: resp.UNPAID_AR,
                    },
                    db,
                    tableHeaders
                ),
                RefreshData(
                    {
                        table: "OUTSTANDING_WIP",
                        endpoint: `${apiRoot}/api/wip/outstanding/full`,
                        remoteversion: resp.OUTSTANDING_WIP,
                    },
                    db,
                    tableHeaders
                ),
                RefreshData(
                    {
                        table: "ENGAGEMENT",
                        endpoint: `${apiRoot}/api/engagement`,
                        remoteversion: resp.ENGAGEMENT,
                    },
                    db,
                    tableHeaders
                ),
                RefreshData(
                    {
                        table: "SERVICES",
                        endpoint: `${apiRoot}/api/services`,
                        remoteversion: resp.SERVICES,
                    },
                    db,
                    tableHeaders
                ),
                RefreshData(
                    {
                        table: "STAFF",
                        endpoint: `${apiRoot}/api/staff/all/`,
                        remoteversion: resp.STAFF,
                    },
                    db,
                    tableHeaders
                ),
                RefreshData(
                    {
                        table: "JOB_TEMPLATES",
                        endpoint: `${apiRoot}/api/jobs/templates`,
                        remoteversion: resp.JOB_TEMPLATES,
                    },
                    db,
                    tableHeaders
                ),
                RefreshData(
                    {
                        table: "JOBS",
                        endpoint: `${apiRoot}/api/jobs/trailingquarter`,
                        remoteversion: resp.JOBS
                    },
                    db,
                    tableHeaders
                ),
                RefreshData(
                    {
                        table: "ENTITY",
                        endpoint: `${apiRoot}/api/ncto/entity`,
                        remoteversion: true,
                    },
                    db,
                    tableHeaders
                ),
                RefreshData(
                    {
                        table: "INDUSTRIES",
                        endpoint: `${apiRoot}/api/ncto/industries`,
                        remoteversion: true,
                    },
                    db,
                    tableHeaders
                ),
                RefreshData(
                    {
                        table: "OFFICES",
                        endpoint: `${apiRoot}/api/ncto/offices`,
                        remoteversion: true,
                    },
                    db,
                    tableHeaders
                ),
                RefreshData(
                    {
                        table: "CONTACTS",
                        endpoint: `${apiRoot}/api/ncto/contacts`,
                        remoteversion: true,
                    },
                    db,
                    tableHeaders
                ),
                RefreshData(
                    {
                        table: "ENTERED_CLIENTS",
                        endpoint:
                            USER.BOI === 1 || USER.NCTO_APPROVE
                                ? `${apiRoot}/api/ncto/allentered`
                                : `${apiRoot}/api/ncto/myentered/${USER.STAFFINDEX}`,
                        remoteversion: true,
                    },
                    db,
                    tableHeaders
                ),
                // RefreshData(
                //     {
                //         table: 'STATIC_AR',
                //         endpoint:
                //             USER.DEPT_AR === 1
                //                 ? `${apiRoot}/api/ar/static/dept/${STAFF_DEPT}-${USER.STAFFINDEX}`
                //                 : USER.RESPONSIBLE_AR === 1
                //                     ? `${apiRoot}/api/ar/static/responsible/${USER.STAFFINDEX}`
                //                     : null,
                //         remoteversion: true
                //     },
                //     db,
                //     tableHeaders
                // ),
                // RefreshData(
                //     {
                //         table: 'STATIC_WIP',
                //         endpoint:
                //             USER.DEPT_WIP === 1
                //                 ? `${apiRoot}/api/wip/static/dept/${STAFF_DEPT}-${USER.STAFFINDEX}`
                //                 : USER.RESPONSIBLE_WIP === 1
                //                     ? `${apiRoot}/api/wip/static/responsible/${USER.STAFFINDEX}`
                //                     : null,
                //         remoteversion: true
                //     },
                //     db,
                //     tableHeaders
                // )
            ]).then(() => {
                console.log('Data refreshed')
                setDataRefreshingState(false)
            })
            // const viewsToLoad = {
            //     database: db,
            //     tableHeaders: {
            //         authorization:
            //             bearer,
            //     },
            //     tables: [
            //         // {
            //         //     table: "UNPAID_AR",
            //         //     endpoint: `${apiRoot}/api/ar/unpaid/full`,
            //         //     remoteversion: resp.UNPAID_AR,
            //         // },
            //         // {
            //         //     table: "OUTSTANDING_WIP",
            //         //     endpoint: `${apiRoot}/api/wip/outstanding/full`,
            //         //     remoteversion: resp.OUTSTANDING_WIP,
            //         // },
            //         {
            //             table: "ENGAGEMENT",
            //             endpoint: `${apiRoot}/api/engagement`,
            //             remoteversion: resp.ENGAGEMENT,
            //         },
            //         {
            //             table: "SERVICES",
            //             endpoint: `${apiRoot}/api/services`,
            //             remoteversion: resp.SERVICES,
            //         },
            //         {
            //             table: "STAFF",
            //             endpoint: `${apiRoot}/api/staff/all/`,
            //             remoteversion: resp.STAFF,
            //         },
            //         {
            //             table: "JOB_TEMPLATES",
            //             endpoint: `${apiRoot}/api/jobs/templates`,
            //             remoteversion: resp.JOB_TEMPLATES,
            //         },
            //         {
            //             table: "JOBS",
            //             endpoint: `${apiRoot}/api/jobs/trailingquarter`,
            //             remoteversion: resp.JOBS
            //         },
            //         {
            //             table: "ENTITY",
            //             endpoint: `${apiRoot}/api/ncto/entity`,
            //             remoteversion: true,
            //         },
            //         {
            //             table: "INDUSTRIES",
            //             endpoint: `${apiRoot}/api/ncto/industries`,
            //             remoteversion: true,
            //         },
            //         {
            //             table: "OFFICES",
            //             endpoint: `${apiRoot}/api/ncto/offices`,
            //             remoteversion: true,
            //         },
            //         {
            //             table: "CONTACTS",
            //             endpoint: `${apiRoot}/api/ncto/contacts`,
            //             remoteversion: true,
            //         },
            //         {
            //             table: "ENTERED_CLIENTS",
            //             endpoint:
            //                 USER.BOI == 1 || USER.NCTO_APPROVE
            //                     ? `${apiRoot}/api/ncto/allentered`
            //                     : `${apiRoot}/api/ncto/myentered/${USER.STAFFINDEX}`,
            //             remoteversion: true,
            //         },
            //         {
            //             table: 'STATIC_AR',
            //             endpoint:
            //                 USER.DEPT_AR == 1
            //                     ? `${apiRoot}/api/ar/static/dept/${USER.DEPARTMENT}-${USER.STAFFINDEX}`
            //                     : USER.RESPONSIBLE_AR == 1
            //                         ? `${apiRoot}/api/ar/static/responsible/${USER.STAFFINDEX}`
            //                         : null,
            //             remoteversion: true
            //         },
            //         {
            //             table: 'STATIC_WIP',
            //             endpoint:
            //                 USER.DEPT_WIP === 1
            //                     ? `${apiRoot}/api/wip/static/dept/${USER.DEPARTMENT}-${USER.STAFFINDEX}`
            //                     : USER.RESPONSIBLE_WIP === 1
            //                         ? `${apiRoot}/api/wip/static/responsible/${USER.STAFFINDEX}`
            //                         : null,
            //             remoteversion: true
            //         },
                    // {
                    //     table: "REVIEWS",
                    //     endpoint: `${apiRoot}/api/reviews/all`,
                    //     remoteversion: true,
                    // },
                // ],
            // };
            // for (let i = 0; i < viewsToLoad.tables.length; ++i) {
            //     let refreshedData = DataRefresher(
            //         viewsToLoad.tables[i],
            //         viewsToLoad.database,
            //         viewsToLoad.tableHeaders
            //     );
            //     refreshedData.then(() => {
            //         console.log("Data Refreshed");
            //         if (i === viewsToLoad.tables.length) {
            //             setDataRefreshingState(false);
            //         }
            //     });
            // }
        });
    }, [apiRoot, bearer, USER]);

    // useEffect(() => {
    //     const staticToLoad = {
    //         database: db,
    //         tableHeaders: {
    //             authorization:
    //                 bearer,
    //         },
    //         tables: [
    //             {
    //                 table: "STATIC_RESP_AR",
    //                 endpoint: `${apiRoot}/api/ar/static/responsible/${USER.STAFFINDEX}`,
    //             },
    //             {
    //                 table: "STATIC_DEPT_AR",
    //                 endpoint: `${apiRoot}/api/ar/static/dept/${USER.DEPARTMENT}`,
    //             },
    //             {
    //                 table: "STATIC_RESP_WIP",
    //                 endpoint: `${apiRoot}/api/wip/static/responsible/${USER.STAFFINDEX}`,
    //             },
    //             {
    //                 table: "STATIC_DEPT_WIP",
    //                 endpoint: `${apiRoot}/api/wip/static/dept/${USER.DEPARTMENT}`,
    //             },
    //             // {
    //             //   table: "STATIC_TEAM_WIP",
    //             //   endpoint: `${apiRoot}/api/wip/static/team/${USER.HOMEROOM}`,
    //             // },
    //         ],
    //     };

    //     for (let i = 0; i < staticToLoad.tables.length; ++i) {
    //         UpdateLessChangelog(
    //             staticToLoad.tables[i],
    //             staticToLoad.database,
    //             staticToLoad.tableHeaders
    //         );
    //     }
    // }, [apiRoot, bearer, USER.DEPARTMENT, USER.STAFFINDEX]);

    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
        position: "absolute",
        top: "65px",
    };

    return (
        <PrimeReactProvider>
            <Loading dataRefreshingState={dataRefreshingState} override={override} />
            <ActiveUserRoute
                setShowHelp={setShowHelp}
            />
            <Help visible={showHelp} showCallBack={setShowHelp} />
        </PrimeReactProvider>
    );
}
