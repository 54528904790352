import { useState, useRef, useEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../utils/DataRefresher";
import Search from "./Search";
import { Controller } from "react-hook-form";
import ClientDetails from "./ClientDetails";

import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

export default function ClientGeneral({
    panelClasses,
    panelState,
    onExpand,
    fieldData,
    formControl,
    originatorList,
    clientCodeData,
    getValues,
    setValue,
    watch,
    countries
}) {
  const clientStateOptions = [
    { label: "New", value: "new" },
    { label: "Existing", value: "existing" },
  ];

  const clientScopeStateOptions = [
    { label: "Individual", value: "IND" },
    { label: "Organization", value: "ORG" },
  ];

  const ref = useRef(null);
  const [relationshipList, setRelationshipList] = useState([]);
  const [relationshipFiltered, setRelationshipFilitered] = useState(null);
  const [relationTypeVals, setRelationTypeVals] = useState({
    state: "Client Status",
    placeholder: "Select New or Exsisting",
  });

    const officeList = useLiveQuery(async () => {
        const data = await db.OFFICES.toArray();
        return data;
    });

  // useEffect(() => {
  //   console.log("data loaded");
  // }, [clientCodeData]);

  useEffect(() => {
    if (panelState === true) {
      ref.current.expand();
    } else if (panelState === false) {
      ref.current.collapse();
    }
  }, [panelState]);

  watch("clientName")
  const clientRelationship = watch("clientRelationship");

  // console.log(clientCodeData)

  return (
    <Panel
      header={<span className="ncto-panel-title">Client Details</span>}
      pt={{ content: { className: panelClasses } }}
      ref={ref}
      toggleable
      onExpand={() => onExpand(0)}
    >
      {/* <Divider align='left'><div className='inline-flex align-items-center'><b>General Information</b></div></Divider> */}
      <div className="w-full border-bottom-2">
        <b>General Information</b>
      </div>
      <div id="client-name" className="w-6">
        <h6>Client Name</h6>
        <Controller
          name="clientName"
          rules={
            { required: "Client Name Required.", 
                validate: {
                    active: value => !clientCodeData.active.some(v => v.CLIENTNAME.toUpperCase() === value.toUpperCase()) || 'A client with this name already exists in PE!',
                    lost: value => !clientCodeData.lost.some(v => v.CLIENTNAME.toUpperCase() === value.toUpperCase()) || 'A client with this name already exists in PE!'
                }
            }
          }
          control={formControl}
          defaultValue={""}
          render={({ field }) => (
            <InputText
              className="w-full"
              onBlur={e => field.onBlur(e.target.value)}
              id={field.name}
              placeholder="Enter Client Name"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </div>
      <div
        id="client-new-existing"
        className="w-3 flex flex-wrap justify-content-center"
      >
        <h6 className="w-full text-center">New or Existing Client?</h6>
        <Controller
          name="clientRelationship"
          rules={{ required: "Must Declare If Client Is New Or Existing" }}
          control={formControl}
          render={({ field }) => (
            <SelectButton
              className="justify-content-center"
              id={field.name}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                setValue('relationshipValue', '')
                // console.log(getValues('relationshipValue'))
                if (e.value === "new") {
                  setRelationTypeVals({
                    state: "New Client Originator",
                    placeholder: "Select Client Originator",
                  });
                  setRelationshipList(
                    originatorList.map((employee) => {
                      return {
                        label: employee.EMPLOYEE,
                        value: employee.STAFFINDEX,
                      };
                    })
                  );
                } else if (e.value === "existing") {
                  setRelationTypeVals({
                    state: "Existing Client",
                    placeholder: "Select Client Sub-Code",
                  });
                  setRelationshipList(clientCodeData.active);
                }
              }}
              options={clientStateOptions}
              // pt={{button: {className:'w-8'}}}
            />
          )}
        />
      </div>
      <div
        id="client-scope"
        className="w-3 flex flex-wrap justify-content-center"
      >
        <h6 className="w-full text-center">Individual or Organization?</h6>
        <Controller
          name="clientScope"
          rules={{ required: "Type required." }}
          control={formControl}
          render={({ field }) => (
            <SelectButton
              className="justify-content-center"
              id={field.name}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                if (getValues("clientScope") === "IND") {
                  setValue("entitySelection", { NAME: "INDIVIDUAL", INDEX: 7 });
                  setValue("industrySelection", {
                    NAME: "Individual",
                    INDEX: "53",
                  });
                }
              }}
              options={clientScopeStateOptions}
              // pt={{button: {className:'w-8'}}}
            />
          )}
        />
      </div>

                <div id="office-selection" className="w-4 px-2">
                    <h6>Client Office</h6>
                    <Controller
                        name="officeSelection"
                        rules={{ required: "Select Office." }}
                        control={formControl}
                        render={({ field }) => (
                            <Dropdown
                                className="w-full"
                                id={field.name}
                                inputId={field.name}
                                placeholder="Select Office"
                                value={field.value}
                                options={officeList}
                                optionLabel="NAME"
                                onChange={(e) => field.onChange(e.value)}
                                showClear
                            />
                        )}
                    />
                </div>

      <div id="relationship-selection" className="w-8 px-2">
        <h6>{relationTypeVals.state}</h6>
        <Controller
          name="relationshipValue"
          rules={{ required: "Must Select Parent Client Or Originator." }}
          control={formControl}
          render={({ field }) => (
            <AutoComplete
              className="w-full"
              id={field.name}
              inputId={field.name}
              field="label"
              placeholder={relationTypeVals.placeholder}
              value={field.value}
              suggestions={relationshipFiltered}
              completeMethod={(e) =>
                Search(e, relationshipList, setRelationshipFilitered)
              }
              inputRef={field.ref}
              onChange={(e) => field.onChange(e.value)}
              itemTemplate={(item) => {
                return item.label;
              }}
              virtualScrollerOptions={{ itemSize: 30 }}
              dropdown
              forceSelection={true}
              disabled={clientRelationship === "" ? true : false}
            />
          )}
        />
      </div>

      <ClientDetails
        fieldData={fieldData}
        formControl={formControl}
        getValues={getValues}
        watch={watch}
        countries={countries}
      />
      <div className="w-full flex justify-content-end">
        <Button type="button" label="Next" onClick={() => onExpand(1)} />
      </div>
    </Panel>
  );
}
