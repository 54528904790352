import { Fragment } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector } from "react-redux";

export default function ArNav() {
    const user = useSelector(state => state.user)

    const { TOP_AR, DEPT_AR, RESPONSIBLE_AR, DEPARTMENT} = user

    if (TOP_AR === 1 || DEPT_AR === 1 || RESPONSIBLE_AR === 1) {
      return (
        <Fragment>
            <Dropdown.Header>AR Reports</Dropdown.Header>
            {TOP_AR === 1 ? <Dropdown.Item href="/reports/ar/firm">Firm AR</Dropdown.Item> : <Fragment></Fragment>}
            {/* {DEPT_AR === 1 ? <Dropdown.Item href="/reports/ar/dept">{DEPARTMENT} AR</Dropdown.Item> : <Fragment></Fragment>} */}
            {RESPONSIBLE_AR === 1 ? <Dropdown.Item href="/reports/ar/responsible">My Client Responsible AR</Dropdown.Item> : <Fragment></Fragment>}
            <Dropdown.Divider />
        </Fragment>
        
      )
    } else {
        <Fragment></Fragment>
    }
}