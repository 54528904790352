import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'user',
  initialState: {
      authenticated: 'pending',
      EMPLOYEE_NUMBER: '',
      OFFICE: '',
      BVSA: '',
      STAFFINDEX: 75,
      EMPLOYEE: '',
      TOP_AR: 0,
      TOP_WIP: 0,
      TOP_BILL: 0,
      TOP_HR: 0,
      DEPT_AR: 0,
      DEPT_WIP: 0,
      DEPT_BILL: 0,
      DEPT_HR: 0,
      TEAM_WIP: 0,
      TEAM_HR: 0,
      RESPONSIBLE_AR: 0,
      RESPONSIBLE_WIP: 0,
      RESPONSIBLE_BILL: 0,
      SELF_WIP: 1,
      SELF_HR: 1,
      LEVEL: '',
      DEPARTMENT: '',
      HOMEROOM: '',
      _HIDDEN_EMPLOYEE: '',
      bearer: "bbm1rhbDTwWL21pX/WiiInOpZTGmZNALBD11vIzpLUoVrkhwLVReniRBOXSiKHnnNUsX34BqMQHcluT/JaH/ra5toQ+aklElysuoh+KAZ+qpvr6itaH3+Aez67hp4+iYtHHRM3AsQ7feKtsnH6Cfi4XCNrCwPILmwiorhXYuTBiAdpDgfARrjpyFr0V+oLYVkc3KBHNtUKbr4A45Rj9ju0B2lyJcM6fRcigrFgzei/DbNrZiZbqw3Y65cPWPcFV1y55kgY3wBVmDwPB5POQyohReS+fOK3DZJpWnbKe3Ynagf6wAQzdQWhn0NH8HVyi2n5sFKIODbJAd+tUtSm+PDg=="
  },
  reducers: {
    setAuthentication: (state, auth) => {
        state.authenticated = auth.payload
    },
    setUser: (state, user) => {
        // const newState = {
        //     authenticated: true, 
        //     ...user.payload
        // }

          return user.payload
    },
    clearUser: () => {
      console.log('here')
      console.log(Object.keys(sessionStorage))
      for (let i = 0; i < sessionStorage.length; i++) {
        console.log(sessionStorage.key(i))
        sessionStorage.removeItem(sessionStorage.key(i))
      }
      // sessionStorage.removeItem('persist:root')
      //   return {
      //     authenticated: 'pending',
      //     bearer: "TkQ6kYJB3Prcgq8WBJjv2XJijJM9SNAONNYPMGVgOkHZGbCc1D+rxh8Pa7NMee/1q0zKqZy6WJAjqxuYBMwBxwo13gFiSDG0ZoLWanfB5nLbm/TOlO3QC5HYrrF5A1AQ7/o83zaCKuBA7nSY1UMJjAqHqK7a74YGb71V0PQJT6l4hcEklPJWpD9o0+4KNasdSK7wwM9gtePQWze5prR+Ff1VAM9WpaSlfi+6oYZwE/ZAApt/WJKDW2UWTcuCXNDiLqswAgwz4JSZhMd5KNCSJCPCfNJh7FucVZoD2Xv1FxxJ0h6oPrIH/2BXEoDN3RKLuAMOQhke5CFATxmrjHszxQ=="
      // }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAuthentication, setUser, clearUser } = loginSlice.actions

export default loginSlice.reducer