import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Slider } from 'primereact/slider'
import { InputNumber } from 'primereact/inputnumber';
import { ExportExcel } from '../../utils/exporters'

import DefaultDataTable from 'react-data-table-component';
import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import "../../style/ar-tables.css"
import { current } from '@reduxjs/toolkit';

export function DefaultTable(props) {

    const { columns, selector } = props
    let data = useSelector(state => state.data.data[selector])
    if (props.data) {
        data = props.data
    }

    return (
        <DefaultDataTable columns={columns} data={data} striped />
    )
}

export function DataViewTable({ data, onChange, columnList, scrollHeight, customColumn }) {

    const [dataTable, setDataTable] = useState(data);
    const currentData = useRef(dataTable)
    const dt = useRef(null);

    const [filters, setFilters] = useState({
        CLIENT: { value: null, matchMode: FilterMatchMode.IN },
        CODE: { value: null, matchMode: FilterMatchMode.IN },
        CLIENT_PARTNER: { value: null, matchMode: FilterMatchMode.IN },
        CLIENT_MANAGER: { value: null, matchMode: FilterMatchMode.IN },
        OFFICE: { value: null, matchMode: FilterMatchMode.IN },
        AGING_PERIOD: { value: null, matchMode: FilterMatchMode.IN },
        OUTSTANDING: { value: null, matchMode: FilterMatchMode.BETWEEN },
        JOB_NAME: { value: null, matchMode: FilterMatchMode.IN }
    })

    // useEffect(()=>{
    // 	onChange(dataTable);
    // }, [onChange, dataTable])


    // const filterData = useCallback((value) => {
    // 		// setDataTable(value)
    // 		onChange(dataTable);
    // }, [dataTable, onChange])

    const outstandingTemplate = (value) => {
        // return value
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const multiselectTemplate = (options) => {
        // console.log("The options outputs: ", options)
        let optionsList = [];
        let placeholder = "";
        switch (options.field) {
            case "OFFICE":
                optionsList = columnList['OFFICE'];
                placeholder = "Office";
                break;
            case "AGING_PERIOD":
                placeholder = "Aging Period"
                optionsList = columnList['AGING_PERIOD'];
                break;
            default:
                break;
        };
        return (
            <MultiSelect
                filter
                value={options.value}
                options={optionsList}
                className="column-filter"
                onChange={(e) => options.filterApplyCallback(e.value)}
                display="chip"
                placeholder={placeholder}
                showSelectAll="false"
            />
        )
    }

    const multiFilterTemplate = (options) => {
        // console.log(options);
        let optionsList = [];
        let placeholder = "";
        switch (options.field) {
            case "CLIENT":
                optionsList = columnList['CLIENT'];
                placeholder = "Client";
                break;
            case "CLIENT_MANAGER":
                optionsList = columnList['CLIENT_MANAGER'];
                placeholder = "Client Manager";
                break;
            case "CLIENT_PARTNER":
                optionsList = columnList['CLIENT_PARTNER'];
                placeholder = "Partner"
                break;
            case "CODE":
                optionsList = columnList['CODE'];
                placeholder = "Code"
                break;
            default:
                break;
        };
        return (
            <span>
                <MultiSelect
                    value={options.value}
                    options={optionsList}
                    onChange={(e) => options.filterApplyCallback(e.value)}
                    className="lg-column-filter column-filter w-full"
                    placeholder={placeholder}
                    display="chip"
                    filter
                />
            </span>
        )
    }


    const sliderFilterTemplate = (options) => {
        return (
            <Fragment>
                <Slider value={options.value} onChange={(e) => options.filterCallback(e.value)} range className="m-3"></Slider>
                <div className="flex align-items-center justify-content-between px-2">
                    <span>{options.value ? options.value[0] : 0}</span>
                    <span>{options.value ? options.value[1] : 100}</span>
                </div>
            </Fragment>
        )
    };

    const moneyRangeFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
    };

    const clientBodyTemplate = (rowData) => {
        return (
            <span>{rowData.CLIENT}</span>
        )
    };

    const saveSheet = () => {
        ExportExcel(currentData.current);
    };

    const paginatorRight = () => {
        return <Button type="button" icon="pi pi-download" onClick={saveSheet} data-pr-tooltip="XLS" text />;
    };

    function* renderSwitch(list) {
        for (let item in list) {
            switch (item) {
                case "CLIENT":
                    yield (
                        <Column
                            key="CLIENT"
                            className="lg-column column"
                            header="Client"
                            field="CLIENT"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='CLIENT'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "CODE":
                    yield (
                        <Column
                            key="CODE"
                            className="sm-column column"
                            header="Code"
                            field="CODE"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='CODE'
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "CLIENT_MANAGER":
                    yield (
                        <Column
                            key="CLIENT_MANAGER"
                            className="md-column column"
                            header="Client Manager"
                            field="CLIENT_MANAGER"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='CLIENT_MANAGER'
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "CLIENT_PARTNER":
                    yield (
                        <Column
                            key="CLIENT_PARTNER"
                            className="md-column column"
                            field="CLIENT_PARTNER"
                            header="Partner" sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='CLIENT_PARTNER'
                            filterElement={multiFilterTemplate}>
                        </Column>
                    );
                    break;
                case "JOB_NAME":
                    yield (
                        <Column
                            key="JOB_NAME"
                            className="md-column column"
                            field="JOB_NAME"
                            header="Job" sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='JOB_NAME'
                            filterElement={multiFilterTemplate}>
                        </Column>
                    );
                    break;
                case "OFFICE":
                    yield (
                        <Column
                            key="OFFICE"
                            className="sm-column column"
                            field="OFFICE"
                            header="Office" sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='OFFICE'
                            filterMatchMode="in"
                            filterElement={multiselectTemplate}>
                        </Column>
                    );
                    break;
                case "AGING_PERIOD":
                    yield (
                        <Column
                            key="AGING_PERIOD"
                            className="sm-column column"
                            field="AGING_PERIOD"
                            header="Aging Period" sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='AGING_PERIOD'
                            filterMatchMode="in"
                            filterElement={multiselectTemplate}>
                        </Column>
                    );
                    break;
                case "OUTSTANDING":
                    yield (
                        <Column
                            key="OUTSTANDING"
                            className="md-column column"
                            field="OUTSTANDING"
                            filterField="OUTSTANDING"
                            header="Outstanding"
                            sortable body={outstandingTemplate}>
                        </Column>
                    );
                    break;
                case "WIPOUTSTANDING":
                    yield (
                        <Column
                            key="OUTSTANDING"
                            className="md-column column"
                            dataType="numeric"
                            field="OUTSTANDING"
                            // filter
                            showFilterMatchModes={false}
                            showFilterMenu={true}
                            filterClear="false"
                            filterField="OUTSTANDING"
                            filterElement={sliderFilterTemplate}
                            header="Outstanding"
                            sortable
                            body={value => outstandingTemplate(value.WIPOUTSTANDING)}>
                        </Column>
                    );
                    break;
                case "OUTSTANDING_WIP":
                    yield (
                        <Column
                            key="OUTSTANDING_WIP"
                            className="md-column column"
                            dataType="numeric"
                            field="OUTSTANDING_WIP"
                            // filter
                            showFilterMatchModes={false}
                            showFilterMenu={true}
                            filterClear="false"
                            filterField="OUTSTANDING_WIP"
                            filterElement={sliderFilterTemplate}
                            header="Outstanding WIP"
                            sortable
                            body={value => outstandingTemplate(value.OUTSTANDING_WIP)}>
                        </Column>
                    );
                    break;
                case "UNPAID_AR":
                    yield (
                        <Column
                            key="UNPAID_AR"
                            className="md-column column"
                            dataType="numeric"
                            field="UNPAID_AR"
                            // filter
                            showFilterMatchModes={false}
                            showFilterMenu={true}
                            filterClear="false"
                            filterField="UNPAID_AR"
                            filterElement={sliderFilterTemplate}
                            header="Unpaid AR"
                            sortable
                            body={value => outstandingTemplate(value.UNPAID_AR)}>
                        </Column>
                    );
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <section className="data-view-table">
            <DataTable
                ref={dt}
                columnResizeMode="expand" resizableColumns
                onValueChange={filteredData => {
                    onChange(filteredData);
                    currentData.current = filteredData;
                }}

                filters={filters}
                onFilter={(e) => setFilters(e.filters)}

                value={dataTable}
                tableStyle={{ minWidth: '50rem' }}
                showGridlines
                stripedRows
                sortMode="multiple"
                filterDisplay="row"
                paginator rows={25}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                dataKey="id"
                emptyMessage="No Clients Found"
                scrollable
                scrollHeight={scrollHeight}
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                paginatorRight={paginatorRight}
            >
                {[...renderSwitch(columnList)]}
                {customColumn}

            </DataTable>
        </section>
    )
};