import ReactEChart from 'echarts-for-react';



export function UnpaidARChart (chartData) {

    if (chartData.chartData) {
    const totalOutstanding = chartData.chartData.OUTSTANDING;
    const percentCurr = chartData.chartData.PERCENT_CURR === 0 ? 0 :  chartData.chartData.PERCENT_CURR.toFixed(2);
    const percent31 = chartData.chartData.PERCENT_31_60.toFixed(2);
    const percent61 = chartData.chartData.PERCENT_61_90.toFixed(2);
    const percentOverdue = chartData.chartData.PERCENT_OVERDUE.toFixed(2);

    const pieChartOptions = {
        tooltip: {
        trigger: 'item'
        },
        graphic: {
            elements: [{
              type: 'text',
              left: 'center',
              top: 'middle',
              z: 999,
              style: {
                text: '',
                textAlign: 'center',
                fontSize: 26,
              }
            }]
          },
        series: [
        {
            name: 'Unpaid AR',
            type: 'pie',
            radius: ['55%', '95%'],
            avoidLabelOverlap: true,
            startAngle: 180,
            label: {
            show: false,
            position: 'center'
            },
            emphasis: {
                label: {
                    position: 'inside',
                    show: false,
                    fontSize: 20,
                    fontWeight: 'bold',
          
                    // formatter(param) {
                    //     // correct the percentage
                    //     return param.name + ' (' + param.percent + '%)';
                    // },
                    itemStyle: {
                        z: 999,
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                }
            },
            data: [
            { value: percentCurr, name: 'Current', itemStyle: {color: "#5C6FC5"}},
            { value: percent31, name: '31-60 Days', itemStyle: {color: "#95CB77"}},
            { value: percent61, name: '61-90 Days', itemStyle: {color: "#F4CA5E"}},
            { value: percentOverdue, name: 'Overdue', itemStyle: {color: "#FF7070"}},
            ],
        }
        ]
    }
    return (<ReactEChart option={pieChartOptions} className='upaid-ar-pie-chart' style={{height: '200px', width: '275px'}}/>)
  }
}

export function OutstandingWIPChart (chartData) {

  if (chartData.chartData) {
  const totalOutstanding = chartData.chartData.OUTSTANDING;
  const percentCurr = chartData.chartData.PERCENT_CURR === 0 ? 0 :  chartData.chartData.PERCENT_CURR.toFixed(2);
  const percent31 = chartData.chartData.PERCENT_31_60.toFixed(2);
  const percent61 = chartData.chartData.PERCENT_61_90.toFixed(2);
  const percentOverdue = chartData.chartData.PERCENT_OVERDUE.toFixed(2);

  const pieChartOptions = {
      tooltip: {
      trigger: 'item'
      },
      graphic: {
          elements: [{
            type: 'text',
            left: 'center',
            top: 'middle',
            z: 999,
            style: {
              text: '',
              textAlign: 'center',
              fontSize: 26,
            }
          }]
        },
      series: [
      {
          name: 'Outstanding WIP',
          type: 'pie',
          radius: ['55%', '95%'],
          avoidLabelOverlap: true,
          startAngle: 180,
          label: {
          show: false,
          position: 'center'
          },
          emphasis: {
              label: {
                  position: 'inside',
                  show: false,
                  fontSize: 20,
                  fontWeight: 'bold',
        
                  // formatter(param) {
                  //     // correct the percentage
                  //     return param.name + ' (' + param.percent + '%)';
                  // },
                  itemStyle: {
                      z: 999,
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
              }
          },
          data: [
          { value: percentCurr, name: 'Current', itemStyle: {color: "#5C6FC5"}},
          { value: percent31, name: '31-60 Days', itemStyle: {color: "#95CB77"}},
          { value: percent61, name: '61-90 Days', itemStyle: {color: "#F4CA5E"}},
          { value: percentOverdue, name: 'Overdue', itemStyle: {color: "#FF7070"}},
          ],
      }
      ]
  }
  return (<ReactEChart option={pieChartOptions} className='upaid-ar-pie-chart' style={{height: '200px', width: '275px'}}/>)
}
}

export function ReviewChart (chartData) {

  if (chartData.chartData) {
  const thumbsUp = chartData.chartData.THUMBSUP;
  const thumbsDown = chartData.chartData.THUMBSDOWN
  const okay = chartData.chartData.OKAY;

  const pieChartOptions = {
      tooltip: {
      trigger: 'item'
      },
      graphic: {
          elements: [{
            type: 'text',
            left: 'center',
            top: 'middle',
            z: 999,
            style: {
              text: '',
              textAlign: 'center',
              fontSize: 26,
            }
          }]
        },
      series: [
      {
          name: 'Reviews',
          type: 'pie',
          radius: ['55%', '95%'],
          avoidLabelOverlap: true,
          startAngle: 180,
          label: {
          show: false,
          position: 'center'
          },
          emphasis: {
              label: {
                  position: 'inside',
                  show: false,
                  fontSize: 20,
                  fontWeight: 'bold',
        
                  // formatter(param) {
                  //     // correct the percentage
                  //     return param.name + ' (' + param.percent + '%)';
                  // },
                  itemStyle: {
                      z: 999,
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
              }
          },
          data: [
          { value: thumbsUp, name: 'Thumbs Up', itemStyle: {color: "#5C6FC5"}},
          { value: okay, name: 'Okay', itemStyle: {color: "#95CB77"}},
          { value: thumbsDown, name: 'Thumbs Down', itemStyle: {color: "#FF7070"}},
          ],
      }
      ]
  }
  return (<ReactEChart option={pieChartOptions} className='upaid-ar-pie-chart' style={{height: '200px', width: '275px'}}/>)
}
}

export function RingGuageUtilization (props) {

}