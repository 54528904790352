import { Fragment } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector } from "react-redux";

export default function PowerBINav() {
    const level = useSelector(state => state.user.STAFFLEVEL)
    const department = useSelector(state => state.user.DEPARTMENT)

    if (level === 'Member' || department === 'Administration - Human Resources') {
      return (
        <Fragment>
            <Dropdown.Header>Power BI Reports</Dropdown.Header>
            {level === 'Member' ? <Dropdown.Item href="/reports/powerbi/member">Demo Dashboard</Dropdown.Item> : <Fragment></Fragment>}
            {/* {department === 'Administration - Human Resources' ? <Dropdown.Item href="/reports/powerbi/hr">M+M Power BI</Dropdown.Item> : <Fragment></Fragment>} */}
        </Fragment>
        
      )
    } else {
        <Fragment></Fragment>
    }
}