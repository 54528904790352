import { useState } from "react"
import { db } from "../../utils/DataRefresher";
import { useLiveQuery } from "dexie-react-hooks";
import { DataViewTable } from "../visualizations/Tables"
import { OutstandingWIPChart } from "../visualizations/charts/PieCharts";
import { DataCards } from "../visualizations/Cards";

import { Column } from 'primereact/column';

import ClipLoader from "react-spinners/ClipLoader";
// import DataTable from 'react-data-table-component'


export default function OutstandingWIP({filter, type, header, query, onChange}) {
        
    // const [filterID, setFilterID] = useState(filter);
    const [clientList, setClients] = useState([])
    const [clientManagerList, setClientManagerList] = useState([])
    const [officeList, setOfficeList] = useState([]);
    const [clientPartnerList, setClientPartnerList] = useState([]);
    const [agingPeriodList, setAgingPeriod] = useState([]);
    // const [codeList, setCodeList] = useState([])

    const lists = {
        CLIENT: clientList,
        // CODE: codeList,
        CLIENT_PARTNER: clientPartnerList,
        CLIENT_MANAGER: clientManagerList,
        OFFICE: officeList,
        AGING_PERIOD: agingPeriodList,
        OUTSTANDING_WIP: null
    }

    // const unpaidAR = useLiveQuery(
    //     async ()=> {
    //         let query;
    //         let ord;

    //         const filtConst = (filtId, field) => {
    //             const filtQuery = new RegExp(filtId );
    //             return filtQuery.test(field)
    //         };
    //         const filt = (test) => filtConst(filterID, test);

    //         switch (type) {
    //             case 'responsible':
    //                 ord = "CLIENT_PARTNER_IDX"
    //                 query = {"CLIENT_PARTNER_IDX": filterID.toString()};
    //                 break;
    //             case 'department':
    //                 ord = "CLIENT_PARTNER_DEPT"
    //                 query = {"CLIENT_PARTNER_DEPT": filterID};
    //                 break;
    //             default:
    //                 query = null
    //                 break;
    //         }
    //         console.log(query)
    //         const data = await db.UNPAID_AR.where(query).toArray();
   
    //         // Need to abstract this out to a callable function.
    //         // Doing that to allow for these attributes to be dynamically 
    //         // loaded from the data actually in the table.

    //         await db.OUTSTANDING_WIP.orderBy("CLIENT").filter((dept) => filt(dept[ord])).uniqueKeys()
    //             .then((data) => {setClients(data)});

    //         // await db.OUTSTANDING_WIP.orderBy("CODE").filter((dept) => filt(dept[ord])).uniqueKeys()
    //         //     .then((data) => {setCodeList(data)});

    //         await db.OUTSTANDING_WIP.orderBy("CLIENT_MANAGER").filter((dept) => filt(dept[ord])).uniqueKeys()
    //             .then((data) => {setClientManagerList(data)});

    //         await db.OUTSTANDING_WIP.orderBy("CLIENT_PARTNER").filter((dept) => filt(dept[ord])).uniqueKeys()
    //             .then((data) => {setClientPartnerList(data)});

    //         await db.OUTSTANDING_WIP.orderBy("OFFICE").uniqueKeys()
    //             .then((data) => {setOfficeList(data)});

    //         await db.OUTSTANDING_WIP.orderBy("AGING_PERIOD").uniqueKeys()
    //             .then((data) => {setAgingPeriod(data)});

    //         onChange(data)
    //         return data
    //     },
    //     [filterID]
    // );

    const outstandingWIP = useLiveQuery(async () => {
        if (type === 'all') {
            const wip = await db.OUTSTANDING_WIP.toArray()

            await db.OUTSTANDING_WIP.orderBy("CLIENT").uniqueKeys()
                .then(data => {setClients(data)})
                
            await db.OUTSTANDING_WIP.orderBy("CLIENT_MANAGER").uniqueKeys()
                .then(data => {setClientManagerList(data)})
                
            await db.OUTSTANDING_WIP.orderBy("CLIENT_PARTNER").uniqueKeys()
                .then(data => {setClientPartnerList(data)})
                
            await db.OUTSTANDING_WIP.orderBy("OFFICE").uniqueKeys()
                .then(data => {setOfficeList(data)})
                
            await db.OUTSTANDING_WIP.orderBy("AGING_PERIOD").uniqueKeys()
                .then(data => {setAgingPeriod(data)})
                

            return wip.map(invoice => {
                return {
                    CLIENT: invoice.CLIENT,
                    CLIENT_MANAGER: invoice.CLIENT_MANAGER,
                    CLIENT_PARTNER: invoice.CLIENT_PARTNER,
                    OFFICE: invoice.OFFICE,
                    AGING_PERIOD: invoice.AGING_PERIOD,
                    OUTSTANDING_WIP: invoice.OUTSTANDING_WIP
                }
            })
        } else if (type === 'dept') {
            const wip = await db.OUTSTANDING_WIP.where('CLIENT_PARTNER_IDX').equals(filter).or('CLIENT_MANAGER_IDX').equals(filter).toArray()

            await db.OUTSTANDING_WIP.orderBy("CLIENT").uniqueKeys()
                .then(data => {setClients(data)})
                
            await db.OUTSTANDING_WIP.orderBy("CLIENT_MANAGER").uniqueKeys()
                .then(data => {setClientManagerList(data)})
                
            await db.OUTSTANDING_WIP.orderBy("CLIENT_PARTNER").uniqueKeys()
                .then(data => {setClientPartnerList(data)})
                
            await db.OUTSTANDING_WIP.orderBy("OFFICE").uniqueKeys()
                .then(data => {setOfficeList(data)})
                
            await db.OUTSTANDING_WIP.orderBy("AGING_PERIOD").uniqueKeys()
                .then(data => {setAgingPeriod(data)})
                

            return wip.map(invoice => {
                return {
                    CLIENT: invoice.CLIENT,
                    CLIENT_MANAGER: invoice.CLIENT_MANAGER,
                    CLIENT_PARTNER: invoice.CLIENT_PARTNER,
                    OFFICE: invoice.OFFICE,
                    AGING_PERIOD: invoice.AGING_PERIOD,
                    OUTSTANDING_WIP: invoice.OUTSTANDING_WIP
                }
            })
        } else if (type === 'resp') {
            const wip = await db.OUTSTANDING_WIP.where('CLIENT_PARTNER_IDX').equals(filter).or('CLIENT_MANAGER_IDX').equals(filter).toArray()

            await db.OUTSTANDING_WIP.orderBy("CLIENT").uniqueKeys()
                .then(data => {setClients(data)})
                
            await db.OUTSTANDING_WIP.orderBy("CLIENT_MANAGER").uniqueKeys()
                .then(data => {setClientManagerList(data)})
                
            await db.OUTSTANDING_WIP.orderBy("CLIENT_PARTNER").uniqueKeys()
                .then(data => {setClientPartnerList(data)})
                
            await db.OUTSTANDING_WIP.orderBy("OFFICE").uniqueKeys()
                .then(data => {setOfficeList(data)})
                
            await db.OUTSTANDING_WIP.orderBy("AGING_PERIOD").uniqueKeys()
                .then(data => {setAgingPeriod(data)})
                

            return wip.map(invoice => {
                return {
                    CLIENT: invoice.CLIENT,
                    CLIENT_MANAGER: invoice.CLIENT_MANAGER,
                    CLIENT_PARTNER: invoice.CLIENT_PARTNER,
                    OFFICE: invoice.OFFICE,
                    AGING_PERIOD: invoice.AGING_PERIOD,
                    OUTSTANDING_WIP: invoice.OUTSTANDING_WIP
                }
            })
        }
        
    })


    if (!outstandingWIP) {return null};


    console.log(outstandingWIP)

    const chartData = {
        PERCENT_CURR: outstandingWIP.map(ar => ar['AGING_PERIOD'] === '0-30 Days' ? ar.OUTSTANDING_WIP : 0).reduce((acc, curr) => acc + curr, 0),
        PERCENT_31_60: outstandingWIP.map(ar => ar['AGING_PERIOD'] === '31-60 Days' ? ar.OUTSTANDING_WIP : 0).reduce((acc, curr) => acc + curr, 0),
        PERCENT_61_90: outstandingWIP.map(ar => ar['AGING_PERIOD'] === '61-90 Days' ? ar.OUTSTANDING_WIP : 0).reduce((acc, curr) => acc + curr, 0),
        PERCENT_OVERDUE: outstandingWIP.map(ar => ar['AGING_PERIOD'] === 'Overdue WIP' ? ar.OUTSTANDING_WIP : 0).reduce((acc, curr) => acc + curr, 0)
    }

    return (
        <section className="data-layout">
            <section className='data-charts'>
                <div className="data-ar-chart">
                    <OutstandingWIPChart chartData={chartData} />
                </div>
                <div className="data-ar-cards">
                <DataCards title='Current' color='#5C6FC5' value={chartData.PERCENT_CURR.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} />
                <DataCards title='31-60 Days' color='#95CB77' value={chartData.PERCENT_31_60.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}/>
                <DataCards title='61-90 Days' color='#F4CA5E' value={chartData.PERCENT_61_90.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}/>
                {/* {DAYS_31_60 > 0 ? <DataCards title='31-60 Days' color='#F4CA5E' value={DAYS_31_60} /> : <Fragment></Fragment>}
                {DAYS_61_90 > 0 ? <DataCards title='61-90 Days' color='#5C7BD9' value={DAYS_61_90}/> : <Fragment></Fragment>}
                {DAYS_91_120 > 0 ? <DataCards title='91-120 Days' color='#FC8451' value={DAYS_91_120}/> : <Fragment></Fragment>}
                {OVERDUE > 0 ? <DataCards title='Overdue' color='#E66B68' value={OVERDUE} /> : <Fragment></Fragment>}  */}
                <DataCards title='Overdue AR' color='#FF7070' value={chartData.PERCENT_OVERDUE.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} />
                </div>
            </section>
            <section className="data-view-table">
                <DataViewTable 
                    data={outstandingWIP} 
                    columnList={lists} 
                    scrollHeight="45vh" 
                    onChange={() => true}
                />
            </section>

        </section>
       
    )
    // const [filterID, setFilterID] = useState(filter);
    // const outstandingWIP = useLiveQuery(
    //     async ()=> {
    //         let query;
    //         let ord;

    //         const filtConst = (filtId, field) => {
    //             const filtQuery = new RegExp(filtId );
    //             return filtQuery.test(field)
    //         };
    //         const filt = (test) => filtConst(filterID, test);

    //         switch (type) {
    //             case 'responsible':
    //                 ord = "CLIENT_PARTNER_IDX"
    //                 query = {"CLIENT_PARTNER_IDX": filterID.toString()};
    //                 break;
    //             case 'department':
    //                 ord = "CLIENT_MANAGER_DEPT"
    //                 query = {"CLIENT_MANAGER_DEPT": filterID};
    //                 break;
    //             // case 'team':
    //             //     ord = "PARTNER_HOMEROOM";
    //             //     query = {"PARTNER_HOMEROOM": filterID};
    //             //     break;
    //             default:
    //                 query = null
    //                 break;
    //         }
    //         const data = await db.OUTSTANDING_WIP.where(query).toArray();
   
    //         // Need to abstract this out to a callable function.
    //         // Doing that to allow for these attributes to be dynamically 
    //         // loaded from the data actually in the table.

    //         await db.OUTSTANDING_WIP.orderBy("CLIENT").filter((dept) => filt(dept[ord])).uniqueKeys()
    //             .then((data) => {setClients(data)});

    //         // await db.OUTSTANDING_WIP.orderBy("CODE").filter((dept) => filt(dept[ord])).uniqueKeys()
    //         //     .then((data) => {setCodeList(data)});

    //         await db.OUTSTANDING_WIP.orderBy("CLIENT_MANAGER").filter((dept) => filt(dept[ord])).uniqueKeys()
    //             .then((data) => {setClientManagerList(data)});

    //         await db.OUTSTANDING_WIP.orderBy("CLIENT_PARTNER").filter((dept) => filt(dept[ord])).uniqueKeys()
    //             .then((data) => {setClientPartnerList(data)});

    //         await db.OUTSTANDING_WIP.orderBy("OFFICE").uniqueKeys()
    //             .then((data) => {setOfficeList(data)});

    //         await db.OUTSTANDING_WIP.orderBy("AGING_PERIOD").uniqueKeys()
    //             .then((data) => {setAgingPeriod(data)});

    //         data.OUTSTANDING = data.WIPOUTSTANDING;
    //         onChange(data);
    //         return data
    //     },
    //     [filterID]
    // );

    // const [clientList, setClients] = useState([])
    // const [clientManagerList, setClientManagerList] = useState([])
    // const [officeList, setOfficeList] = useState([]);
    // const [clientPartnerList, setClientPartnerList] = useState([]);
    // const [agingPeriodList, setAgingPeriod] = useState([]);
    // // const [codeList, setCodeList] = useState([])

    // const lists = {
    //     CLIENT: clientList,
    //     // CODE: codeList,
    //     CLIENT_MANAGER: clientManagerList,
    //     CLIENT_PARTNER: clientPartnerList,
    //     OFFICE: officeList,
    //     AGING_PERIOD: agingPeriodList,
    //     WIPOUTSTANDING: null
    // }

    // if (!outstandingWIP) {return null};
    // console.log("The Data is: ", outstandingWIP)
    // return (
    //     <section className="data-view-table">
    //         <DataViewTable 
    //             data={outstandingWIP} 
    //             columnList={lists} 
    //             scrollHeight="56vh" 
    //             onChange={onChange}
    //         />
    //     </section>
    // )
    // // } else {
    // //     return (
    // //         <ClipLoader loading={arData} />
    // //     )
    // // }
}